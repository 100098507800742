:where(
    .css-dev-only-do-not-override-1c0na6j
  ).ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-layout {
  font-family: "Gilroy", sans-serif !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
}
/* :where(.css-dev-only-do-not-override-1c0na6j).ant-tabs .ant-tabs-ink-bar {
  background-color: #077255 !important;
  height: 5px !important;
  border-radius: 10px;
} */
/* .ant-tabs-nav-wrap {
  border-bottom: 1px solid #00a971;
} */

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light
  .ant-menu-item-selected {
  background-color: #249b6f !important;
  /* color: #077255 !important; */
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  /* margin-block: 25px; */
  padding: 8px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light
  .ant-menu-item-selected:hover {
  color: #fff;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #077255 !important;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover {
  color: #fff;
}
:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light .ant-menu-item {
  color: #fff;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  margin-block: 25px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-layout
  .ant-layout-sider-zero-width-trigger {
  top: 0;
  inset-inline-end: -22px;
  background: #9b9fa3;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-inline .ant-menu-item {
  margin-block: 20px;
}
.green_button {
  background-color: #28ae52;
  color: #fff;
  border: none;
  cursor: pointer;
}
/* .green_button:hover {
  opacity: 0.5;
} */

:where(.css-dev-only-do-not-override-1c0na6j).ant-pagination
  .ant-pagination-options {
  margin-right: 1rem;
}
/* .customer_detail_table .ant-table-tbody-virtual-holder{
  height: 200px;
} */
.footer_btn {
  display: flex;
  gap: 0.5rem;
  justify-content: end;
}

.ant-table-tbody-virtual-scrollbar-thumb {
  background: #b6b0b0 !important;
}

.ant-table-tbody-virtual-scrollbar-vertical {
  visibility: visible;
}

.ant-table-tbody-virtual-scrollbar-horizontal {
  /* background: #b6b0b0 !important; */
  visibility: visible;
}

.save_btn {
  background-color: #00a971;
  border: none;
  color: #fff;
  padding: 8px;
  width: 7rem;
  border-radius: 5px;
  cursor: pointer;
}

.cancel_btn {
  background-color: #fff;
  border: 1px solid #00a971;
  color: #00a971;
  padding: 8px;
  width: 7rem;
  border-radius: 5px;
  cursor: pointer;
}

.ant-spin .ant-spin-dot i {
  background-color: #28ae52;
}

.customSpinner {
  color: #00a971;
}

.cancel_btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #28a745;
  color: #28a745;
}

.approve_btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #28a745;
  color: #fff;
  background: #28a745;
}

/* .background_div.ant-card .ant-card-body {
  padding: 5rem 21px;
}

.background_div.ant-card {
  background: #d9edd6 !important;
  font-family: "Gilroy", sans-serif !important;
  height: auto;
} */
.viewScrollableDiv .recharts-wrapper.chart-container svg {
  width: 75% !important;
}
.cancel_btn.ant-btn-default {
  color: #28a745;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  border-color: #28a745;
  padding: 15px;
}

.approve_btn.ant-btn-default {
  background: #28a745;
  padding: 15px;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: #fff;
}

/* .ant-modal-content{
  height: 90vh;
} */

/* .ant-modal-body {
  height: 90%;
  overflow: scroll;
} */

/* .ant-table-body::-webkit-scrollbar {
  width: 8px; 
} */

/* .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
} */

.extraCardItems:where(.css-dev-only-do-not-override-1c0na6j).ant-btn-default {
  width: 4rem;
  border: 1px solid #28ae52;
  color: #28ae52;
}

/* .ant-table-container {
  min-height: 20vh;
} */
/* span.ant-input-affix-wrapper.css-dev-only-do-not-override-1c0na6j.ant-input-outlined.searchbar{
  border-color: #28ae52;
} */

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-checkbox .ant-checkbox-inner {
  height: 12px;
  width: 12px;
  border-radius: 0;
} */

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-item-active a {
  color: #28ae52;
  border-color: transparent;
  
}
 */

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-checkbox .ant-checkbox-input {
  padding: 12px, 24px, 12px, 19px;
} */

.web_scroll::-webkit-scrollbar {
  width: 0.1em;
  height: 12px;
}
.web_scroll::-webkit-scrollbar-thumb {
  background: #c1bdbd;
  border: 10px solid #f7f7f7;
}
