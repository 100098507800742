.login_btn.ant-btn-default:hover {
    border: 1px solid #00A971 !important;
    color: #00A971 !important;
  }
  
.login_btn:hover {
    background-image: linear-gradient(to right, #28AE52, #2AA353);
    
  }



.PasswordInput.css-dev-only-do-not-override-1c0na6j.ant-input-affix-wrapper {
    height: 52px; /* Adjust the height as needed */
    padding: 0 12px; /* Adjust padding if needed */
    line-height: 48px;
    font-size: 16px;
    border-radius: 10px;
    border:1px solid #D3E8CF
}

.PasswordInput.css-dev-only-do-not-override-1c0na6j.ant-input {
    padding-left: 0;
}
  
  
  