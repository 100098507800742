.login_main {
  display: flex;
  // width: 100%;
  width: 100vw;
  height: 100vh;
  gap: 1.2rem;
  // overflow: scroll;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;

  .backgroundImage_div {
    // height: 100vh;
    // display: flex;
    // flex: 0 0 47%;
    // padding: 0px 30px;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    justify-content: end;
    width: 43%;
    height: 95%;
    // align-items: center;
    background-color: #f7f7f7;
    // margin-bottom: 1rem;
    .background_div {
      height: 85%;
      width: 85%;
      background-image: linear-gradient(to bottom, #e9f7ee, #fafefc);
      border-radius: 20px;
      display: flex;
      // align-content: center;
      // justify-content: center;
      flex-direction: column;
      padding: 0 1rem;
      svg {
        height: 60%;
        width: 100%;
      }

      .Approve_text {
        color: #131313;
        font-size: 24px;
        line-height: 29px;
        // font-weight: 500;
      }
      .Approve_paragraph {
        color: #434343;
        font-size: 14px;
        // line-height: 28px;
        margin-top: 20px;
      }
    }
    // justify-content: center;
    // .background_div{
    //   background-color: #D9EDD6;
    //   font-family: "Gilroy", sans-serif !important;
    //   height: 100%;
    //   padding: 10px;

    // }
    // width:300px;
    //  .background_img{
    //   width: 555px;
    //   height: 555px;
    //  }
  }

  // .login_form_container {
  //   width: 53%;
  //   overflow: hidden;
  //   display: grid;
  //   align-content: center;
  //   // padding: 4rem 0px;
  // //   background-color: #f7f7f7;
  // height: 98%;
  //   // height: 100%;
  //   .login_form_main {
  // flex: 1 1 calc(100vh - 60px);
  // padding: 4rem 0px;
  .login_form {
    width: 45%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    background-color: #f7f7f7;
    // gap: .5rem;
    height: 85vh;
    .request_amt {
      color: #131313;
      font-size: 18px;
      line-height: 30px;
      font-weight: 600;
    }

    .amount {
      color: #131313;
      font-size: 26px;
      line-height: 42px;
      font-weight: 500;
    }
    .approve_stock_rows {
      margin: 20px 0px 0px 0px;
      min-height: 16.5rem;
      height: 315px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
    }

    .winsight_div{
      display: flex;
      align-items: center;
      justify-content: start;
      gap: .5rem;
      margin-bottom: 1rem;
    }

    .winsight_logo{
      width: 45px;
      height: 45px;
    }

    .stock_paragraph {
      color: #131313;
      font-size: 14px;
      font-weight: 600;
    }

    .stock_card {
      background-color: #fbfffa;
      border: 1px solid #d9edd6;
    }

    .login_logo {
      // align-items: center;
      padding-bottom: 1.5rem;
    }

    .table_header {
      color: #727d7a;
      font-size: 12px;
      line-height: 21px;
      font-weight: 600;
    }

    .table_data {
      color: #2e2e2e;
      font-size: 12px;
      line-height: 21px;
      font-weight: 600;
      padding: 13px 0px;
    }

    .scroll_table {
      height: 320px;
      overflow: auto;
    }

    .approve_stock_rows::-webkit-scrollbar {
      width: 0.2em;
      height: 12px;
    }

    .approve_stock_rows::-webkit-scrollbar-thumb {
      background-color: #e1dede;
      /* // border-radius: 6px; */
    }

    .approve_stock_rows::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
}
.login_footer {
  flex: 0 0 60px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #84828a;
  height: 2%;

  .footer_text {
    font-size: 14px;
    line-height: 15.5px;
    color: #84828a;
    font-weight: 400;
    display: flex;
    gap: 63px;
    //   font-family: 'Inter';
  }
  // }
  // }

  @media (max-width: 960px) {
    .backgroundImage_div {
      // flex: 0 0 20%;
      width: 50%;
      .background_img {
        width: 400px;
        // height: 400px;
      }
    }

    // .login_form_container {
    //   padding: 17px;
    //   height: 100%;
    // .login_form_main {
    .login_form {
      // width: 400px;
      .login_caption {
        margin-block-end: 20px;
      }
      .login_logo {
        padding-bottom: 10px;
        margin-left: 0;
      }
    }
    // }
    // }
  }

  @media only screen and (max-width: 768px) {
    // flex-direction: column;

    .backgroundImage_div {
      display: none;
    }

    // .login_form_container {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background: none;
    //   padding: 0;
    //   background-color: #f7f7f7;
    //   width: 100%;
    //   // padding: 17px;
    //   height: 100%;
    // .login_form_main {
    //   padding: 5rem;
    .login_form {
      // width: 370px;
      .login_caption {
        margin-block-end: 20px;
      }
      .login_logo {
        padding-bottom: 10px;
      }
      .space_div {
        float: none;
      }
    }
    // background-color: rgba(255, 255, 255, 0.9);
    // border-radius: 10px;
    // }
    // }
  }

  @media only screen and (max-width: 600px) {
    // .login_form_container {
    //   padding: 16px;
    //   height: 100%;
    .login_form_main {
      .login_form {
        width: 315px;
      }
    }
  }
  // }
}

@media (min-width: 250px) and (max-width: 550px) {
  .background_div{
    display: none !important;
  }
  .backgroundImage_div{
    height: 3rem !important;
    width: 90% !important; 
  }
  .login_main{
    flex-direction: column;
    height: 100vh !important;
    gap: 3rem;
  }
  .login_form{
    width: 80% !important;
    height: 70% !important;
  }
}