.login_main {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #F7F7F7;
  
    .backgroundImage_div {
        height: 100vh;
        display: flex;
        flex: 0 0 40%;
        align-items: center;
        background-color: #F7F7F7;
        justify-content: center;
      // width:300px;

    }
  
    .login_form_container {
      // width: 60%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    //   background-color: #f0f2f5;
      height: 100%;
      .login_form_main {
        // flex: 1 1 calc(100vh - 60px);
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 98%;
        .login_form {
          width: 550px;
          display: flex;
    flex-direction: column;
    align-items: center;
    .login_logo {
      // align-items: center;
      // padding-bottom: 7rem;
      position: relative;
      bottom: 7rem;
    }
    .middle_div{
      display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 3rem;
      .welcome_text {
        margin: 0;
        font-size: 35px;
        line-height: 44px;
        font-weight: 700;
        // text-align: center;
        // font-family: 'Inter';
        color: #28A745;
      }
      .login_caption {
        margin: 0;
        margin-top: 10px;
        margin-block-end: 40px;
        color: #1E1E1E;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        text-align: center;
        // font-family: 'Inter';
      }
     
      .approve_button{
        margin-top: 3rem;
        height: 40px;
        width: 200px;
        background-color: #28A745;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
      .approve_button:hover{
        margin-top: 3rem;
        height: 40px;
        width: 200px;
        background-color: #28A745;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border-color: #28A745;
      }
      .bottom_text{
        color: #84828A;
        margin-top: 20px;
      }
    }
         
        }
      }
      .login_footer {
        flex: 0 0 60px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #84828A;
        height: 2%;
       
        .footer_text {
          font-size: 14px;
          line-height: 15.5px;
          color: #84828A;
          font-weight: 400;
          display: flex;
          gap: 63px;
        //   font-family: 'Inter';
          
        }
      }
    }

    @media (max-width: 960px) {
      .backgroundImage_div {
        // flex: 0 0 20%;
        width: 50%;
       
      }
  
      .login_form_container {
        padding: 17px;
        height: 100%;
        .login_form_main {
          .login_form {
            width: 400px;
            .login_caption {
              margin-block-end: 20px;
            }
            .login_logo {
              padding-bottom: 10px;
              margin-left: 0;
             
            }
          }
        }
      }
    }
  
    @media (max-width: 720px) {
      // flex-direction: column;
      // .backgroundImage_div {
      //   flex: 0 0 20%;
      //   width: 50%;
       
      // }
      .login_form_container {
        padding: 17px;
        height: 100%;
        .login_form_main {
          .login_form {
            width: 400px;
            .login_caption {
              margin-block-end: 20px;
            }
            .login_logo {
              padding-bottom: 10px;
              margin-left: 0;
             
            }
          }
        }
      }
  
     
    }
  
    @media only screen and (max-width: 712px) {
      // .backgroundImage_div {
      //   display: none;
      // }
      .login_form_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: none;
        padding: 0;
        background-color: #f0f2f5;
        width: 100%;
        padding: 17px;
        height: 100%;
        .login_form_main {
          .login_form {
            width: 370px;
            .login_caption {
              margin-block-end: 20px;
            }
            .login_logo {
              padding-bottom: 10px;
            }
          }
          // background-color: rgba(255, 255, 255, 0.9);
          // border-radius: 10px;
        }
      }
      // .login_form_container {
      //   padding: 16px;
      //   height: 100%;
      //   .login_form_main {
      //     .login_form {
      //       width: 315px;
      //     }
      //   }
      // }
    }
  
    
  }
  