.computedResultsCard.ant-card .ant-card-head {
  border-bottom: none;
}

.computedResultsCard.ant-card .ant-card-head-title {
  color: #1d1d1d;
  font-size: 12px;
  font-weight: 500;
}

.computedResultsCard.ant-card {
  border-radius: 15px;
  border: 1px solid #dddddd;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 12px;
}

.computedResultsCard .ant-card .ant-card-body {
  padding: 0;
}

.head-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stock_listing_searchbar.ant-input-affix-wrapper > input.ant-input {
  color: #a49f9f;
  font-weight: 500;
  font-size: 12px;
  padding: 0 8px;
}

.stock_listing_searchbar.ant-input-outlined:hover {
  border-color: #dddddd;
  box-shadow: none;
}
.stock_listing_searchbar:focus-within {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}

.stock_listing_table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #555e74;
  font-size: 12px;
  line-height: 21px;
  width: 100%;
  padding: 12px 11px 12px 14px;
}

.stock_listing_table.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 16px;
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.stock_listing_table.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
  /* height: 280px; */
}

.stock_listing_table.ant-table-tbody.ant-table-tbody-virtual-scrollbar-horizontal {
  visibility: hidden;
}

.stock_listing_table.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #f5f5f5;
}

.load-more {
  width: 30%;
  border: 1.5px solid #000;
  padding: 10px;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
  color: #000;
  border-radius: 3px;
  margin-top: 1rem;
}

.load-more.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000;
  border: 1.5px solid #000;
}

.stock_listing_table.ant-table-wrapper .ant-table-cell {
  padding: 5px 14px;
}

.loading.ant-spin-dot,
.loading .ant-spin-dot-spin {
  color: #1a8c4df5 !important;
}

.micro {
  background: #f49c05;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.large {
  background: #d73a42;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.mid {
  background: #6f6af8;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.small {
  background: #0fa958;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}
