.header {
    padding: 12px 0 12px 18px;
    border-bottom: 1px solid #DDDDDD;
    font-weight: 600;
    line-height: 14.7px;
    gap: 5px;
    margin-bottom:12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #296E47;
    font-size: 12px;
    span {
        cursor: pointer;
    }
    svg{
        position: relative;
        // bottom: 2px;
    }
}