// *************************
//  SIDEBAR
// *************************




// *************************
//  OUTSIDE CONTAINER
// *************************

.container {
    background-image: linear-gradient(to bottom, #e6f6f1, #fff);
    height: 100%;
}


// *************************
//  CONTENT
// *************************

.main_div {
    height: calc(100vh - 95px);
    overflow: hidden;
  
    /* margin-top: 2rem; */
    /* margin: 2rem 0 0 40px; */
    padding: 20px 14px 10px 0;
  }
  
  .main_div {
    overflow-y: auto;
  }
  
  /* Custom scrollbar styles for WebKit browsers */
  .main_div::-webkit-scrollbar {
    width: 0.2em;
    height: 12px;
  }
  
  .main_div::-webkit-scrollbar-thumb {
    background-color: #c1bdbd;
    /* // border-radius: 6px; */
  }
  
  .main_div::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  

.main_div {
    padding: 0 30px;
    .portfolio_text{
        font-size: 20px;
        color: #1D1D1D;
        font-weight: 600;
        line-height: 24.5px;
       }
      
       
           .portfolio_button{
               background-color: #00A971;
               color: #fff;
               font-size: 14px;
               line-height: 18px;
               padding: 0 15%;
               &:hover{
                   border: 1px solid #00A971 ;
                   background-color: #00A971;
                   color: #fff;
               }
               &:focus-within {
                border: none;
               }
           }
}

.content {
    margin: 15px 15px 9px 9px;
    background-color:#FCFCFC;
    border-radius:20px;
    border: 1px solid #dddddd;
    height: auto;
}

.header {
    padding: 12px 0 12px 18px;
    border-bottom: 1px solid #DDDDDD;
    font-weight: 600;
    line-height: 14.7px;
    color: #296E47;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    margin-bottom:12px
}

.footer_text{
    margin: 0;
    padding: 0;
    margin-bottom: 9px;
    text-align: center;
    color: #808080;
    font-size: 10px;
    font-weight: 400;
}
.Portfolio_layout{
   height: 100vh;
.portfolio_logo{
    display: flex;
    justify-content:start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
   

    .winsightLogo{
        width: 45px;
    height: 45px;
    // background: #16A36F;
    border-radius: 25px;
    padding: 5px;
    }

    .winsightLetter{
        color: #fff;
    font-size: 24px;
    line-height: 27.3px;
}
    
}



.view_text{
    color: #1D1D1D;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
}

.viewDetail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 40px;
    .portfolio_text{
     font-size: 20px;
     color: #1D1D1D;
     font-weight: 600;
    //  line-height: 26.5px;
    }
}

.footerIcons {
    display: flex;
    flex-direction: column;
    padding: 1rem 0px 0px 10px;
    position: absolute;
    bottom: 2rem;
    gap: 15px;
  }
  
  .collapseButton {
    margin-right: 10px;
  }
  
 

.portfolio_div {
    height: calc(100vh - 95px);
    overflow: hidden;
    padding: 20px 14px 10px 0px;
  }
  
  .portfolio_div:hover {
    overflow-y: auto;
  }
  
  /* Custom scrollbar styles for WebKit browsers */
  .portfolio_div::-webkit-scrollbar {
    width: 0.2em;
    height: 12px;
  }
  
  .portfolio_div::-webkit-scrollbar-thumb {
    background-color: #c1bdbd;
    /* // border-radius: 6px; */
  }
  
  .portfolio_div::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

.portfolio_div{
    padding: 0 30px 0 30px;
    .portfolio_text{
     font-size: 20px;
     color: #1D1D1D;
     font-weight: 600;
    //  line-height: 26.5px;
    }

    
   
    
        .portfolio_button{
            background-color: #00A971;
            color: #fff;
            font-size: 14px;
            line-height: 18px;
            width: 100%;
            &:hover{
                border: 1px solid #00A971 ;
                background-color: #00A971;
                color: #fff;
            }
        }
    

    .search_input:hover{
        border-color:#d9d9d9 !important ;
    }

    .search_input{
        // width:  344px;
        // margin-top: 5px;
        // margin-bottom: 10px;
    }

    .search_input:focus-within{
        border-color : #d9d9d9 !important;
        box-shadow: none;
        
    }

    .empty_card{
        display: flex;
    justify-content: center;
    align-items: center;
     margin:20px 0px;
      height: 500px;
      text-align: center;
    }

    .empty_card p {
        font-size:12px;
        line-height: 14.56px;
        color: #1D1D1D;
        margin-top: 5px;
    }

    .table_header h3, .table_header h3 {
        margin: 0;
    }

    .assign_btn {
        display: flex;
        align-items: center;
    }

    .table_label{
     color: #727D7A;
     font-size: 12px;
     font-weight: 500;
    }
    .table_data{
        color:#2E2E2E ;
        font-size: 12px;
        font-weight: 500;
    }

}




 
  
}



