.header {
    padding: 12px 0 12px 18px;
   
    border-bottom: 1px solid #DDDDDD;
    font-weight: 600;
    line-height: 14.7px;
    color: #296E47;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    margin-bottom:12px
}

.customer_tab {
    display: flex;
    align-items: center;
    gap: 5px;
    // border: 1px solid #eeeeee;
}

.customer_div {
    height: calc(100vh - 95px);
    overflow: hidden;
    padding: 10px 6px 10px 0;
  }
  
  .customer_div:hover {
    overflow-y: auto;
  }
  
  /* Custom scrollbar styles for WebKit browsers */
  .customer_div::-webkit-scrollbar {
    width: 0.2em;
    height: 12px;
  }
  
  .customer_div::-webkit-scrollbar-thumb {
    background-color: #c1bdbd;
    /* // border-radius: 6px; */
  }
  
  .customer_div::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  

.customer, .customer_div{
    padding: 0 30px 0 30px;
    // overflow-y: scroll;
    // height: 83%;
    // &::-webkit-scrollbar {
    //     width: 8px; 
    //   }
    .portfolio_text{
     font-size: 20px;
     color: #1D1D1D;
     font-weight: 600;
     margin-bottom: 8px;
    //  line-height: 26.5px;
    }

    .category_label{
        color: #0FA958;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.7px;
        border-bottom: 2px solid #0FA958;
    }

    .stock_label{
        color: #0FA958;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.7px;
        border-bottom: 2px solid #0FA958;
    }

    .customers_icon{
        margin-left: 5px;
        color: #1D1D1D;
        font-size: 19px;
        font-weight: 500;
        line-height: 24.5px;
        .customer_text{
           
            color: #84828A;
            font-size: 10px;
            line-height: 12.25px;
        }
    }

    .table_label{
        color: #727D7A;
        font-size: 12px;
        font-weight: 500;
       }
       .table_data{
           color:#2E2E2E ;
           font-size: 12px;
           font-weight: 500;
       }
    //    .row_div{
    //     margin-top: 10px;
    //     height: calc(100vh - 164px);
    //     overflow-y: auto;
    //    }
       
       .noData_text{
        text-align: center;
    padding: 4rem;
    color: #a49f9f;
    font-weight: 500;
       }
      
      /* Custom scrollbar styles for WebKit browsers */
    //   .row_div::-webkit-scrollbar {
    //     width: 0.2em;
    //     height: 12px;
    //   }
      
    //   .row_div::-webkit-scrollbar-thumb {
    //     background-color: #c1bdbd;
    //     /* // border-radius: 6px; */
    //   }
      
    //   .row_div::-webkit-scrollbar-track {
    //     background: #f1f1f1;
    //   }


    
}

.deactivate_msg{
  padding: 20px;
  color: #2E2E2E;
  font-size: 16px;
  line-height: 28px;
}