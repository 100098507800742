.portfolioRow {
  padding: 0 8px 0px 8px;
}

.portfolioModal.ant-modal {
  top: 10px !important;
  width: 700px !important;
}

.categoryInput.ant-input-outlined:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.riskProfileInput.ant-input-outlined:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.categoryInput.ant-input-outlined:hover {
  border-color: #d9d9d9;
}

.weightageInput.ant-input-outlined:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.weightageInput.ant-input-outlined:hover {
  border-color: #d9d9d9;
}

.stocksInput.ant-input-outlined:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.stocksInput.ant-input-outlined:hover {
  border-color: #d9d9d9;
}

.stocksInput {
  margin-bottom: 15px;
  width: 80%;
}

.categoryInput {
  margin-bottom: 15px;
  width: 80%;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
}
.weightageInput {
  margin-bottom: 15px;
}

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-form-item-label
  > label {
  font-weight: 500;
  margin-top: 12px;
} */

.ant-form-item {
  /* margin-bottom: 0px; */
}

.portfolio-card {
  padding: 2px 6px;
  display: flex;
  min-height: 100%;
  flex: 1;
  flex-direction: column;

  /* margin: 10px 20px 0px 20px; */
}

.portfolio-card.ant-card .ant-card-head {
  padding: 0 6px;
}

.portfolio-card.ant-card .ant-card-body {
  padding: 20px 61px;
}

.portfolio-card.ant-card {
  border-radius: 7px;
  border: 1px solid #ddd;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.ant-divider-horizontal {
  margin: 8px 0;
}

.portfolioModal.ant-modal .ant-modal-title {
  color: #1d1d1d;
  font-size: 18x;
  line-height: 21px;
  font-weight: 500;
  margin-left: 1rem;
}

.portfolioModal.ant-modal .ant-modal-body {
  padding: 20px 40px;
}

.riskProfileLabel.ant-form-item .ant-form-item-label > label {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.columnLabel.ant-form-item .ant-form-item-label > label {
  color: #727d7a;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 10px;
}

.columnLabel .ant-form-item-label {
  margin-bottom: 10px !important;
}

.extraCardItems.ant-btn {
  border: 1.5px solid #00a971;
  height: 27px;
  color: #00a971;
  font-size: 10px;
  font-weight: 500;
}

.extraCardItems.ant-btn:hover {
  border: 2px solid #00a971 !important;
  color: #00a971 !important;
}

.view-detail {
  align-items: right;
}

/* ********************************* */
/* Assign Modal */
/* ********************************* */

:where(.css-dev-only-do-not-override-1c0na6j).ant-modal .ant-modal-content {
  padding: 20px 0;
}

.ant-menu-inline .ant-menu-item {
  padding-inline: 7px;
}
.assignPortfolioModal {
  width: 800px !important;

  /* height: 739px !important; */
  padding: 0;
}

.assignPortfolioModal.ant-modal {
  top: 41px !important;
}

.customTable .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  color: rgb(114, 125, 122);
  font-weight: 500;
  font-size: 12px;
  /* width: 100%; */
}

.customTable .ant-table-tbody > tr > td {
  color: #2e2e2e;
  font-size: 12px;
  font-weight: 500;
}

.searchbar {
  width: 334px;
  height: 32px;
  font-size: 12px;
  border-color: #dddddd !important;
  display: flex;
  justify-content: start;
  gap: 10px;
}

.searchbar:hover {
  border-color: #dddddd;
  box-shadow: 0 0 2px #dddddd;
}

.searchbar:focus {
  border-color: #dddddd;
  box-shadow: 0 0 2px #dddddd;
}

.btn {
  width: 160px;
  height: 40px;
}

.btn--save {
  background-color: #00a971;
  color: #fff;
}

.btn--cancel {
  color: #00a971;
  border-color: #00a971;
}

/* View Details */

.UpperContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 1rem;
  /* padding: 0 40px; */
  /* margin: 0 40px; */
  /* margin-top: 2rem;
  margin-left: 2rem; */
}

/* .UpperContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 2rem;
} */

.graphCard,
.viewDetailCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%; /* Ensures both cards expand based on content */

  .h3 {
    margin-bottom: 2rem;
  }
}

.chart-container {
  flex-grow: 1;
  width: 600px;
}

.table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.PortfolioCard {
  width: 40%;
  height: 300px;
}

.graphCard {
  border-radius: 7px;
  border: 1.5px solid #eeeeee;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.graphCard.css-dev-only-do-not-override-1c0na6j.ant-card .ant-card-body {
  padding: 8px;
}

.viewDetailCard {
  border-radius: 7px;
  border: 1.5px solid #eeeeee;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  padding-left: 24px;
}

.viewDetailCard.ant-card .ant-card-head {
  padding: 0 5px;
}

.table-label {
  color: #727d7a;
  font-size: 12px;
  font-weight: 500;
}

.bottomTable {
  width: 890px;
  margin: 0 24px;
  margin-top: 2rem;
  padding-right: 20px;
  /* position: relative; */
}

.bottomTable h3 {
}

.bottomTable-header {
  margin-bottom: 1rem;
}

.row-header {
  background-color: #eaf4e8;
  padding: 12px;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
  width: 100%;
  color: #2e2e2e;
}

.table-data {
  padding: 7px 8px 0 13px;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  text-align: left;
  color: #2e2e2e;
}

.bottomTableCard {
  width: 100%;
  position: relative;
  padding-top: 3rem;
  max-height: 28rem;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
}
.bottomTableCard::-webkit-scrollbar {
  width: 10px;
}
.stock-details-table-header {
  position: relative;
}
.stock-details-table {
  padding: 12px 12px 15px 12px;
  color: #727d7a;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  position: absolute;
  top: 0.5px;
  left: 1.5px;
  z-index: 99;
  background-color: #fcfcfc;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 99%;
  border-bottom: 1px solid #d9d9d9;
  /* position: sticky; */
}
.bottomTableCard.css-dev-only-do-not-override-1c0na6j.ant-card .ant-card-body {
  padding: 0;
  border: none;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}

.bottomTableCard.css-dev-only-do-not-override-1c0na6j.ant-card-bordered {
  border: 1px solid #eeeeee;
  border-radius: 20px;
}

.message {
  padding: 8px;
  color: #2e2e2e;
}

.editButton {
  border: none;
  height: 18px;
  width: 18px;
  margin-right: 1rem;
}

.editButton:hover {
  border: none;
  stroke: rgba(15, 169, 87, 0.527);
  background-color: #727d7a;
}

.viewDetailButton {
  background-color: #00a971;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 20px;
}

.viewDetailButton:hover {
  border: 1px solid #00a971;
  background-color: #00a971;
  color: #fff;
}

.menuItems {
  position: relative;
  top: 30px;
  background-color: transparent;
}

.collapseItems {
  position: relative;
  top: 30px;
  background-color: transparent;
}

.menuItems .ant-menu-item {
  padding-left: 10px !important;
}

.collapseItems.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}
.collapseItems .ant-menu-item {
  /* padding: 7px 0px 0px 16px; */
}

.collapseItems.ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: calc(50% - 11px - 4px) !important;
}

.logoutButton.ant-btn {
  /* padding: 5px !important; */
  color: #fff;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
  gap: 15px;
  justify-content: flex-start;
}

.logoutButton.ant-btn .ant-btn-icon {
  line-height: 0;
}

.logoutButton.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #077255;
}

.sidebar {
  background: #077255 !important;
  padding: 7px;
}

.sidebar1 {
  background: #077255 !important;
  padding: 7px;
}

.sidebar.ant-layout-sider-collapsed {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}

.confirm_portfolio button.ant-btn.css-dev-only-do-not-override-1c0na6j.ant-btn-primary.ant-btn-sm {
  background-color: #00a971 !important;
}
