.portfolio_button.ant-btn-default {
  background-color: #00a971;
  border-color: none;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 15px;
  height: 32px;
}
.portfolio_button.ant-btn-default:hover {
  background-color: #00a971 !important;
  color: #fff !important;
  border: none;
}

.input_box.ant-form-item {
  margin-bottom: 0px;
}

.configTable.ant-table-body {
  scroll-behavior: smooth;
}
.configTable.ant-table-wrapper .ant-table-cell {
  padding: 10px 16px 5px;
}
.configTable.ant-table-wrapper .ant-table {
  min-height: 200px;
}

.portfolio_button.ant-btn-default {
  box-shadow: none;
}

.filterTable.ant-table-wrapper .ant-table-thead > tr > th {
  color: #727d7a;
  font-size: 12px;
  line-height: 21px;
}

.filterTable.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.filterManagement-header {
  display: flex;
  align-items: center;
}

.configTable.ant-table-wrapper .ant-table-thead > tr > th {
  color: #727d7a;
  font-size: 12px;
  line-height: 21px;
}

.filterCard.ant-card {
  border: 1px solid #dddddd;
  border-radius: 20px;
}

.filterCard.ant-card .ant-card-body {
  padding: 10px;
}

.filterTable .ant-table-tbody-virtual-scrollbar-horizontal {
  visibility: hidden !important;
}

.configTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 16px;
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.configTable .ant-table-tbody-virtual-scrollbar-thumb {
  background: #b6b0b0 !important;
  visibility: visible;
}

.configTable.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

/* .configTable.ant-table-tbody.ant-table-tbody-virtual-scrollbar-horizontal {
  visibility: hidden;
} */
.createFilterCard.ant-card {
  border-radius: 15px;
  border: 1px solid #dddddd;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  /* margin-top: 30px; */
}

.createFilterCard.ant-card .ant-card-body {
  padding: 24px 40px 15px;
}

.filter_input_label.ant-form-item .ant-form-item-label > label {
  font-size: 12px;
  color: #1d1d1d;
  font-weight: 500;
  line-height: 14.56px;
}

.name_input.ant-input-outlined:focus {
  border-color: #eeeeee;
  box-shadow: none;
}
.name_input.ant-input-outlined {
  height: 38px;
  /* border: 1px solid #eeeeee; */
}

.name_input.ant-input-outlined:hover {
  border-color: #eeeeee;
  box-shadow: none;
}

.description_input.ant-input-outlined:focus {
  border-color: #eeeeee;
  box-shadow: none;
}

.description_input.ant-input-outlined:hover {
  border-color: #eeeeee;
  box-shadow: none;
}
.description_input.ant-input-outlined {
  /* border: 1px solid #eeeeee; */
}

.assign_select_input.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #eeeeee;
  box-shadow: none;
}

.assign_select_input.ant-select-focused:where(
    .css-dev-only-do-not-override-1c0na6j
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #eeeeee;
  box-shadow: none;
}

.assign_select_input.ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  min-height: 38px;
  /* border: 1px solid #eeeeee; */
}

.reset_filter.ant-btn-default {
  border: 1px solid #00a971;
  color: #00a971;
  width: 113px;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  padding: 0 20px;
}

.reset_filter.ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #00a971 !important;
}

.compute_result.ant-btn-default {
  border: 1px solid #00a971;
  color: #fff;
  background-color: #00a971;
  /* width: 113px; */
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  padding: 0 20px;
}
.micro {
  background: #f49c05;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.large {
  background: #d73a42;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.mid {
  background: #6f6af8;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.small {
  background: #0fa958;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.compute_result.ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #fff !important;
  background-color: #00a971 !important;
}

.cancel_button.ant-btn-default {
  border: 1px solid #00a971;
  color: #00a971;
  /* width: 113px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.cancel_button.ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #00a971 !important;
}

.save_button.ant-btn-default {
  border: 1px solid #00a971;
  color: #fff;
  background-color: #00a971;
  /* width: 113px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.save_button.ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #fff !important;
  background-color: #00a971 !important;
}

.filterConfigurationCard.ant-card .ant-card-head {
  border-bottom: none;
}

.filterConfigurationCard.ant-card .ant-card-head-title {
  color: #1d1d1d;
}

.filterConfigurationCard.ant-card {
  border-radius: 15px;
  border: 1px solid #dddddd;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 20px;
}

.filterConfigurationCard.ant-card .ant-card-body {
  /* padding: 24px 40px 15px; */
  padding: 10px 0px;
}

.computedResultsCard.ant-card .ant-card-head {
  border-bottom: none;
}

.computedResultsCard.ant-card .ant-card-head-title {
  color: #1d1d1d;
  font-size: 12px;
  font-weight: 500;
}

.computedResultsCard.ant-card {
  border-radius: 15px;
  border: 1px solid #dddddd;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 18px;
}

.computedResultsCard.ant-card .ant-card-body {
  /* padding: 24px 40px 15px; */
  padding: 0px 0px 10px;
}

.toggle.ant-switch.ant-switch-checked {
  background: #0fa958;
}

.toggle.ant-switch.ant-switch-checked:hover {
  background: #0fa958;
}
.toggle.ant-switch .ant-switch-handle {
  top: 1px;
}

.toggle.ant-switch {
  background: #d9d9d9;
  width: 37px;
  height: 21px;
}

.toggle.ant-switch:hover {
  background: #d9d9d9;
}

.input_number.ant-input-number {
  width: 100px;
}

.input_number.ant-input-number .ant-input-number-input:placeholder-shown {
  font-size: 15px;
  font-weight: 500;
}

.input_number.ant-input-number-outlined:focus {
  border-color: #dddddd;
  box-shadow: none;
}

.input_number.ant-input-number-outlined:hover {
  border-color: #dddddd;
  box-shadow: none;
}

.input_number.ant-input-number-outlined:focus-within {
  border-color: #dddddd;
  box-shadow: none;
}

.cancel_button.ant-btn {
  padding: 0 3rem;
}

.save_button.ant-btn {
  padding: 0 3rem;
}
