* {
  padding: 0;
  margin: 0;
}

.main_div {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/images/pawel-czerwinski-RmMCdS-Nh74-unsplash\ 1.png");
  background-size: cover;
  background-color: rgb(37, 74, 39);

  display: flex;
  align-items: center;
  font-family: sans-serif;
}

.container {
  
  display: flex;
  height: 85%;
  max-height: 40rem;
  width: 80%;
    justify-content: center;

  margin: 0 auto;
  background-color: #fff;
  
  .bg_img {
    width: 50%;
    max-width: 489px;
    height: 100%;
  }

}


@media (min-width: 250px) and (max-width: 800px) {
  .left {
    display: none;
  }
}


/********************************/ 
//  LEFT SIDE OF THE CONTAINER 
/********************************/ 

.left {
  width: 45%;
  max-width: 489px;
  height: 100%;
  background-image: linear-gradient(to right, #1A8C4D, #33B45F);
  position: relative;
  overflow: hidden;
}

.left_content {
  width: 85%;
  margin: 0 auto;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
}

.logo img {
  margin-top: 4rem;
  width: 57.35px;
  height: 99.92px;
  
}

.logo p {
  margin-left: 6px;
  margin-bottom: 3rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.15px;
  color: #fff;
}

.left_paragraph {
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.5;
  color: #fff;
}

.small {
  height: 280.73px;
  width: 280.73px;
  background-color: rgba(161, 229, 172, 0.434);
  opacity: 20%;
  border-radius: 50%;
  position: absolute;
  right: -90px;
  top: 50px;
  z-index: 1;
}

.big {
  z-index: 0;
  height: 420.01px;
  width: 420.01px;
  background-color: rgba(146, 217, 157, 0.37);
  opacity: 20%;
  border-radius: 50%;
  position: absolute;
  right: -160px;
  top: -20px;
}
/********************************/ 
//  RIGHT SIDE OF THE CONTAINER 
/********************************/ 
  
.right {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex:1;
  }

.right img {
    width: 30px;
    margin-bottom: 18px;
  }

  
.right h1 {
  
    color: #121417;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 3.5rem;
    
  }

.right p {
    margin-bottom: 72px;
    color: rgba(100, 106, 117, 0.669);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.41px;
    width: 65%;
    text-align: center;
  }

// .input {
//   width: 60%;
//   padding: 20px;
// }

.form {
  width: 326px;
  margin: 0 auto;
}

.formItem {
  width: 100%;
  
}

.formItem Input {
  height: 42px;
  width:100%;
  border: 1px solid #249c5446;
  font-size: 16px !important;
 
}

.input_btn {
  // outline: none !important;
  // box-shadow: 0 0 1px #249c5446 !important;
  border-color: #249c5446;
  border-radius: 10px;
}

.input_btn:focus {
  outline: none !important;
  box-shadow: 0 0 1px #249c5446 !important;
}

.input_btn:hover {
  border-color: #249c5446;
}

.invalid_pass {
  color: red;
  // text-align: center;
}

.resend {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }


.resend span {
    color: #121417;
    font-weight: 400;
    font-size: 12px;
    line-height: 24.03px;
  }

.timer {
    color: rgba(36, 83, 46, 0.914);
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 12px;
    line-height: 24.03px;
  }

.timer:hover {
    text-decoration: underline;
  }

.seconds {
  color: rgba(36, 83, 46, 0.914) !important;
}
  
.login_btn{
      width: 100%;
      height: 3rem;
      border-radius: 13px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 1rem;
  }
  
  