.table_card {
    margin-top: 1rem;
}

.table_card.ant-card .ant-card-body {
    padding: 0 0 10px 0;
    border-radius: 0 0 8px 8px;
}

.document_table.ant-table-wrapper .ant-table-cell {
    padding: 8px 16px;
    font-size: 12px;
    color: #2e2e2e;
    font-weight: 500;
  }

.document_table.ant-table-wrapper .ant-table-thead >tr>th {
    color: #727D7A;
    font-size: 13px;
    font-weight: 500;
}

.document_search_input:hover {
    border-color: #d9d9d9 !important ;
  }
  
  .document_search_input {
    margin-top: 5px;
    width: 300px;
  }
  
  .document_search_input:focus-within {
    border-color: #d9d9d9 !important;
    box-shadow: none;
  }
  
  .document_search_input.ant-input-affix-wrapper {
    color: #a49f9f;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.56px;
    margin-left: 10px;
  }
  
  .document_search_input.ant-input-affix-wrapper > input.ant-input {
    padding-left: 10px;
  }
  
  .document_search_input.ant-input-affix-wrapper > input.ant-input {
    font-weight: 600;
  }


.attach_doc_input.ant-input {
    padding: 13px 11px;
}

.attach_doc_input.ant-input-outlined:hover {
  border-color: #DDDDDD;
}

.label-head {
    font-size: 14px;
    font-weight: 500;
}

.label-info {
    font-size: 12px;
    color: #727D7A;
    margin-bottom: 5px;
}

.upload-input.ant-btn-default {
    color: #FFF;
    background-color: #0FA958;
    box-shadow: none;
    border: none;
}

.upload-input.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    color: #FFF;
    background-color: #0FA958;
    border: none;
}

.attach-doc-card {
    margin-top: 1rem;
}

.attach-doc-card.ant-card .ant-card-body {
  padding: 24px 0 12px 0;
}

.uploaded-file {
    display: flex;
    align-items: center;
    justify-content:space-between; 
    border:1.5px solid #0FA958;
    border-radius:5px;
    color: #0FA958;
    padding: 6px 11px;
}

.attach-doc-row {
  padding:0 32px;
}

.uploaded-file-column {
    margin: auto 0;
}