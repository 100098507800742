.editModal.ant-modal .ant-modal-content {
  padding: 24px 24px;
  width: 600px;
}

.editModal.ant-modal .ant-modal-title {
  font-size: 18px;
  line-height: 21px;
  color: #1d1d1d;
}

.editModal.edit.ant-modal {
  top: 40px !important;
}

.editModal.ant-modal {
  top: 40px;
}

.editModal.anticon svg {
  color: #434343;
}

.editForm.ant-form {
  padding: 0 16px;
}

.nameLabel .ant-form-item-label > label {
  color: #2e2e2e;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 20px !important;
}

.nameLabel .ant-radio-wrapper {
  color: #84828a;
  font-size: 12px;
  line-height: 21px;
}

.Input.ant-input-outlined:focus,
Input.ant-input-outlined:focus-within {
  border-color: #eeeeee;
  box-shadow: none;
}

.Input.ant-input-outlined:hover {
  border-color: #eeeeee;
}

.Input.ant-input {
  /* margin-bottom: 8px; */
  border-radius: 5px;
}

.nameLabel .ant-radio-wrapper-checked {
  /* font-size: 18px; */
  color: #1d1d1d;
  font-weight: 500;
}

.nameLabel.ant-form-item {
  margin-bottom: 0;
}

/* .ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 8px;
  padding-inline-end: 74px;
} */

/* :where(.css-dev-only-do-not-override-1c0na6j).ant-radio-wrapper {
  font-size: 12px;
  margin-bottom: 18px;
} */
