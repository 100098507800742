@font-face {
  font-family: "Gilroy", sans-serif !important;
  src: url("/src/Image/assets/Gilroy-SemiBold.ttf") format("ttf"),
    url("/src/Image/assets/Gilroy-SemiBold.ttf") format("ttf");
  font-style: normal;
}

body {
  font-family: "Gilroy", sans-serif !important;
}
