.tab-col.ant-tabs {
  padding-left: 19px;
  font-size: 14px;

  margin-top: 5px;
}

.tab-col.ant-tabs .ant-tabs-tab-btn {
  color: #5e5e5e;
}

.tab-col.ant-tabs .ant-tabs-tab:hover {
  color: #1d1d1d;
}

.tab-col.ant-tabs .ant-tabs-ink-bar {
  background-color: #077255 !important;
  height: 5px !important;
  border-radius: 10px;
}

.tab-col.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1d1d1d;
  font-weight: 500;
}
.tab-col .ant-tabs-nav-wrap {
  border-bottom: 1px solid #00a971;
}
.head-cards {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.Card.ant-card {
  border: 1px solid #ddd;
  border-radius: 20px;
}

.requestData.ant-card {
  border: 1px solid #ddd;
}

.Card.ant-card .ant-card-body {
  padding: 14px 0 14px 30px;
}

.requestData.ant-card {
  border-radius: 20px;
}

.requestData.ant-card .ant-card-head-wrapper {
  padding: 0 16px;
}

.requestData.ant-card .ant-card-head {
  padding: 0px 16px;
}
.requestData.ant-card .ant-card-head {
  border-bottom: none;
}

.requestData.ant-card .ant-card-head-title {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.6px;
}

.requestData.ant-card .ant-card-body {
  /* padding: 24px 40px 15px; */
  /* padding: 10px 0px; */
  padding: 0;
  padding-bottom: 15px;
}

.RequestTable.ant-table-wrapper .ant-table-cell {
  padding: 10px 16px;
  font-size: 12px;
  color: #2e2e2e;
  font-weight: 500;
}

.search_input:hover {
  border-color: #d9d9d9 !important ;
}

.search_input {
  margin-top: 5px;
  width: 300px;
}

.search_input:focus-within {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}

.search_input.ant-input-affix-wrapper {
  color: #a49f9f !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.56px;
  margin-left: 10px;
}

.search_input.ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px;
}

.search_input.ant-input-affix-wrapper {
  padding: 5px 11px;
}

.RequestTable.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 10px 16px;
  background-color: #f5f5f5;
  color: #727d7a;
  font-weight: 500;
}

.div_scroll {
  height: calc(100vh - 192px);
  overflow: hidden;
  padding-right: 5px;
}

.div_scroll:hover {
  overflow-y: auto;
}

/* Custom scrollbar styles for WebKit browsers */
.div_scroll::-webkit-scrollbar {
  width: 0.2em;
  height: 12px;
}

.div_scroll::-webkit-scrollbar-thumb {
  background-color: #c1bdbd;
  /* // border-radius: 6px; */
}

.div_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.request-btn {
  background-color: #00a971;
  color: #fff;
  margin-left: 10px;
  padding: 14px 20px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.request-btn.ant-btn-primary {
  box-shadow: none;
}

.request-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #00a971;
  box-shadow: none;
  color: #fff;
  border: none;
}

/* REQUEST FORM */

.newRequest.ant-card .ant-card-body {
  padding: 16px 32px;
}

.newRequest.ant-card {
  margin-top: 15px !important;
  border: 1px solid #eeeeee;
  border-radius: 15px;
}

.requestLabel.ant-form-item .ant-form-item-label > label {
  font-weight: 500;
  font-size: 12px;
  color: #1d1d1d;
  line-height: 21px;
}

.rationDescription.ant-input-outlined:hover {
  border-color: #d9d9d9 !important;
}

.request-select.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border: 1px solid #eeeeee;
  box-shadow: none;
}

.request-select.ant-select-focused:where(
    .css-dev-only-do-not-override-1c0na6j
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: 1px solid #eeeeee;
  box-shadow: none;
}

.rationDescription.ant-input-outlined:focus {
  border: 1px solid #eeeeee;
  box-shadow: none;
}

.request-form.ant-input-outlined:hover {
  border: 1px solid #eeeeee;
}

.request-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #00a971;
}

.transactionType .ant-radio-wrapper {
  color: #84828a;
  font-size: 12px;
  line-height: 21px;
}

.transactionType .ant-radio-wrapper-checked {
  /* font-size: 18px; */
  color: #1d1d1d;
  font-weight: 500;
}
.requestLabel .ant-input-number-outlined:focus-within {
  border-color: #eeeeee !important;
  box-shadow: none;
}

.requestLabel .ant-input-number-outlined:hover {
  border-color: #eeeeee !important;
  box-shadow: none;
}

.recommed_stocks {
  height: calc(100vh - 159px);
  overflow: hidden;
  padding: 0px 14px 13px 0px;
}
.recomment_stock_view {
  height: calc(100vh - 133px);
  overflow: hidden;
  padding: 0px 14px 13px 0px;
}

.recommed_stocks:hover {
  overflow-y: auto;
}

/* Custom scrollbar styles for WebKit browsers */
.recommed_stocks::-webkit-scrollbar {
  width: 0.2em;
  height: 12px;
}

.recommed_stocks::-webkit-scrollbar-thumb {
  background-color: #c1bdbd;
  /* // border-radius: 6px; */
}

.recommed_stocks::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.recomment_stock_view:hover {
  overflow-y: auto;
}

/* Custom scrollbar styles for WebKit browsers */
.recomment_stock_view::-webkit-scrollbar {
  width: 0.2em;
  height: 12px;
}

.recomment_stock_view::-webkit-scrollbar-thumb {
  background-color: #c1bdbd;
  /* // border-radius: 6px; */
}

.recomment_stock_view::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.recommed-stock-card.ant-card .ant-card-body {
  padding: 12px 24px;
}

.recommed-stock-table.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #fff;
  font-size: 12px;
  color: #727d7a;
  border-bottom: none;
}

.notification_button.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  background: none;
}

.recommed-stock-table.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none;
  font-size: 12px;
  color: #5e5e5e;
}

.recommed-stock-table.ant-table-wrapper .ant-table-summary > tr > td {
  border-bottom: none;
}

.recommend-table-selector.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #eeeeee;
  box-shadow: none;
}

.recommend-table-selector.ant-select-focused .ant-select-selector {
  border-color: #eeeeee !important;
  box-shadow: none !important;
}

.recommend-table-input.ant-input-outlined:hover {
  border-color: #eeeeee;
  box-shadow: none;
}

.confirm_model.ant-modal .ant-modal-content {
  padding: 2rem;
}

.confirm_model.ant-modal .ant-modal-title {
  color: #1d1d1d;
  font-size: 18px;
  line-height: 21px;
}

.confirm_model.ant-modal .ant-modal-footer {
  text-align: center;
  margin-top: 3rem;
}

.confirm_model.ant-modal .ant-modal-footer .ant-btn {
  padding: 4px 4rem;
  height: 40px;
  border: 1px solid #00a971;
  color: #00a971;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.confirm_model.ant-modal .ant-modal-footer .ant-btn-primary {
  background: #00a971;
  color: #fff;
}
