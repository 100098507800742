* {
  padding: 0;
  margin: 0;
}

.main_div {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../public/images/pawel-czerwinski-RmMCdS-Nh74-unsplash\ 1.png");
  background-size: cover;
  background-color: rgb(37, 74, 39);

  display: flex;
  align-items: center;
  font-family: sans-serif;
}

.container {
  
  display: flex;
  height: 85%;
  max-height: 40rem;
  width: 80%;
    justify-content: center;

  margin: 0 auto;
  background-color: #fff;
  
  .bg_img {
    width: 50%;
    max-width: 489px;
    height: 100%;
  }

}


@media (min-width: 250px) and (max-width: 800px) {
  .left {
    display: none;
  }
}


/********************************/ 
//  LEFT SIDE OF THE CONTAINER 
/********************************/ 

.left {
    width: 45%;
    // max-width: 489px;
    height: 100%;
    background-image: linear-gradient(to right, #297148, #33B45F);
    position: relative;
    overflow: hidden;
}

.left_content {
    width: 85%;
    margin: 0 auto;
}

.logo {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    // background: linear-gradient(to right, #296E47, #33B45F);
    // border-radius: 10px 0px 0px 10px;
    // box-shadow: 2px 4px 9px 0px rgba(0, 0, 0, 0.12);
    
}

.logo img {
    margin-top: 4rem;
    width: 40%;
   
    
}

.logo p {
    margin-left: 6px;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.15px;
    color: #fff;
}

.left_paragraph {
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.5;
    color: #fff;
}

.small {
    height: 280.73px;
    width: 280.73px;
    background-color: rgba(161, 229, 172, 0.434);
    opacity: 20%;
    border-radius: 50%;
    position: absolute;
    right: -90px;
    top: 50px;
    z-index: 1;
}

.big {
    z-index: 0;
    height: 420.01px;
    width: 420.01px;
    background-color: rgba(146, 217, 157, 0.37);
    opacity: 20%;
    border-radius: 50%;
    position: absolute;
    right: -160px;
    top: -20px;
}

/********************************/ 
//  RIGHT SIDE OF THE CONTAINER 
/********************************/ 

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex:1;
}

.right h1 {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 4.5rem;
  line-height: 28px;
}

.errorMessage {
  color: rgba(255, 0, 0, 0.422);
}

.form {
  // width: 326px;
  // margin: 0 auto;
}

.formItem {
  width: 100%;
  
}

.formItem Input {
  height: 52px;
  width:100%;
  border: 1px solid #D3E8CF;
  font-size: 16px;
  border-radius: 10px;
  // margin-bottom: 1rem;
}

.formItem Input:focus {
  outline: none;
  box-shadow: 0 0 2px #249c5446;
  border-color: #249c5446;
}

.formItem Input:hover {
  border-color: #249c5446;
}

.forgot_btn {
  float: right;
  margin-top: .2rem;
  color: #24532E;
  text-decoration: underline;
  font-size: 11px;
}

.forgot_btn:hover {
  color: #1a8c4df5;
}

.login_btn{
    width: 100%;
    height: 3rem;
    border-radius: 13px;
    font-size: 16px;
    font-weight: 600;
}




.submit {
  width: 100%;
  border-radius: 11px;
  margin-top: 32px;
  height: 36px;
  background-color: #249c53;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;

  padding: 20px 40px;
}

@media screen and (max-width: 447px){
  .form{
    padding: 15px;
  }
}

.lockoutCard {
  padding: 2rem;
  background-color: #fff5f5;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  text-align: center;
}

.lockoutCard a {
  color: #007bff;
  cursor: pointer;
}

.lockoutCard a:hover {
  text-decoration: underline;
}
