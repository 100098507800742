/* ********************************* */
/* Assign Modal */
/* ********************************* */

:where(.css-dev-only-do-not-override-1c0na6j).ant-modal .ant-modal-content {
  padding: 20px 0;
}

.header {
  display: flex;
  flex-direction: column;
}

.header h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.header h6 {
  color: #84828a;
  font-size: 12px;
  font-weight: 400;
}

.assignPortfolioModal {
  width: 1000px !important;
  /* height: 300px !important; */
  padding: 0;
}

.assignPortfolioModal.ant-modal {
  top: 41px !important;
}

.customTable .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  color: rgb(114, 125, 122);
  font-weight: 500;
  font-size: 12px;
  /* width: 100%; */
}

.customTable .ant-table-tbody > tr > td {
  color: #2e2e2e;
  font-size: 12px;
  font-weight: 500;
}

.searchbar {
  width: 334px;
  height: 32px;
  font-size: 12px;
  border-color: #dddddd;
  display: flex;
  justify-content: start;
  gap: 10px;
}

.searchbar:hover {
  border-color: #dddddd;
  box-shadow: 0 0 2px #dddddd;
}

.btn {
  width: 160px;
  height: 40px;
}

.btn--save {
  background-color: #00a971;
  color: #fff;
}

.btn--cancel {
  color: #00a971;
  border-color: #00a971;
}

.assignPortfolioModal .ant-btn-default {
  width: 9rem;
  border: 1px solid #00a971;
  color: #00a971;
}

/* Targeting the Save button */
.assignPortfolioModal .ant-btn-primary {
  background-color: #00a971;
  width: 9rem;
  margin-right: 1rem;
}

.customTable .ant-checkbox .ant-checkbox-inner {
  height: 12px;
  width: 12px;
  border-radius: 0;
}

.customTable .ant-pagination .ant-pagination-item-active a {
  color: #00a971;
}

.assignPortfolioModal .ant-btn-primary:hover {
  background-color: #00a971 !important;
}

.assignPortfolioModal .ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #00a971 !important;
}

.scrollableDiv {
  height: calc(100vh - 187px);
  overflow: hidden;
  padding: 18px 14px 10px 0px;
}

.scrollableDiv:hover {
  overflow-y: auto;
}

/* Custom scrollbar styles for WebKit browsers */
.scrollableDiv::-webkit-scrollbar {
  width: 0.2em;
  height: 12px;
}

.scrollableDiv::-webkit-scrollbar-thumb {
  background-color: #c1bdbd;
  /* // border-radius: 6px; */
}

.scrollableDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.portfolio_assign_button.ant-btn-default {
  background-color: #00a971;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}


.portfolio_assign_button.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background-color: #00a971;
  color: #fff;

  border: none;
}

.riskProfileData {
  background: #e3f9e5;
  border-radius: 20px;
  text-align: center;
  padding: 4px 0px;
  color: #51b552;
  font-size: 10px;
  font-weight: 500;
  line-height: 21px;
}

.customTable.ant-table-wrapper .ant-table-cell {
  padding: 10px 16px;
}
