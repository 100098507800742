.header {
  padding: 12px 0 12px 18px;
  border-bottom: 1px solid #dddddd;
  font-weight: 600;
  line-height: 14.7px;
  color: #296e47;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 5px;
  margin-bottom: 12px;
}

.marketCapHeader{
  font-size: 18px;
    color: #202020;
    font-weight: 600;
    line-height: 23.28px;
}

.main_div {
  padding: 0 30px;

  .request_text {
    font-size: 20px;
    color: #1d1d1d;
    font-weight: 600;
    line-height: 24.5px;
  }

  .recommendedTitle {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
    line-height: 19.6px;
    padding: 15px 9px 0px;
  }

  .newReq_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .newReq_leftHead {
      display: flex;
      gap: 1rem;
      h3 {
        font-size: 16px;
        color: #2e2e2e;
      }
      span {
        font-size: 10px;
        background-color: #fff4d9;
        //   width: 7.5rem;
        border-radius: 100px;
        color: #f49c05;
        text-align: center;
        padding: 0.3rem 3rem 0.2rem 3rem;
        font-weight: 600;
      }
    }

    Button {
      width: 6rem;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .newReq_date {
    color: #0fa958;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 12px;
  }
}
.footer_btn {
  background-color: #fff;
  padding: 0.7rem 2.7rem 0.7rem 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  // position: relative;
  // bottom: 7px;
  border-top: 1px solid #dddddd;
}

.deactivate_msg{
  padding: 20px;
  color: #2E2E2E;
  font-size: 16px;
  line-height: 28px;
}