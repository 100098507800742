
.document_div {
    padding: 0 30px;
}

.page_header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
}
 
.attach_doc_div {
    padding: 0 30px;
}

.header {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    margin-bottom: 5px;
}

.customer_info {
    font-size: 12px;
}

.deactivate_msg{
    padding: 20px;
    color: #2E2E2E;
    font-size: 16px;
    line-height: 28px;
  }
