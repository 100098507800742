
.header {
    padding: 12px 0 12px 18px;
    
    border-bottom: 1px solid #DDDDDD;
    font-weight: 600;
    line-height: 14.7px;
    color: #296E47;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    margin-bottom:12px
}


.portfolio_div{
    padding: 0 40px 0 40px;
    .portfolio_text{
     font-size: 20px;
     color: #1D1D1D;
     font-weight: 600;
     line-height: 26.5px;
    }  

    .search_input:hover{
        border-color:#d9d9d9 !important ;
    }

    .search_input{
        
        margin-top: 5px;
        width: 325px;
        height: 32px;
        color: #A49F9F;
        font-weight: 500;
        
    }
    .search_input.ant-input-affix-wrapper >input.ant-input {
      color: #A49F9F;
      font-weight: 700;
    }
    .search_input:focus-within{
        border-color : #d9d9d9 !important;
        box-shadow: none;
        
    }
.computedresultsTitle{
    font-size: 16px;
    font-weight: 600;
    color: #1D1D1D;
    line-height: 19.6px;
    padding: 15px 9px 0px;
}
   
}

.scrollableDiv{
// padding: 0 30px 0 0;

padding: 20px 30px 15px 0px;
height: calc(100vh - 245px);
  overflow: hidden;
.portfolio_text{
 font-size: 20px;
 color: #1D1D1D;
 font-weight: 600;
 line-height: 26.5px;
}  


}

.scrollableDiv:hover {
    overflow-y: auto;
  }
  
  /* Custom scrollbar styles for WebKit browsers */
  .scrollableDiv::-webkit-scrollbar {
    width: 0.2em;
    height: 12px;
  }
  
  .scrollableDiv::-webkit-scrollbar-thumb {
    background-color: #c1bdbd;
    /* // border-radius: 6px; */
  }
  
  .scrollableDiv::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  
.search_input:hover{
    border-color:#d9d9d9 !important ;
}

.search_input{
    // width:  344px;
    margin-top: 5px;
    // margin-bottom: 10px;
}

.search_input:focus-within{
    border-color : #d9d9d9 !important;
    box-shadow: none;
    
}