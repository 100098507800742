.export_button.ant-btn-default {
  background: #00a971;
  padding: 0px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
}

.import_button.ant-btn-default {
  border: 1.3px solid #00a971;

  padding: 0px 20px;
  color: #00a971;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  font-weight: 500;
}

.risk-profile {
  background-color: #e3f6e5;
  color: #0fa958;
  border-radius: 20px;
  text-align: center;
}

.customer-id {
  color: #1a97df;
  text-decoration: underline;
}

.export_button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: #00a971 !important;
  color: #fff;
  border: none;
}
.import_button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #00a971 !important;
  color: #00a971;
}

.totalCustomerCard.ant-card {
  margin-top: 12px;
  border: 1px solid #eeeeee;
}

.totalCustomerCard.ant-card .ant-card-body {
  padding: 12px;
}

.customerDataTable.ant-card {
  margin-top: 18px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.transaction {
}

.customerDataTable.ant-card .ant-card-head-wrapper {
  /* padding: 0 16px; */
}

.customer_logo {
  height: 25px;
  width: 25px;
}

.customerDataTable.ant-card .ant-card-head {
  padding: 0px 16px;
}
.customerDataTable.ant-card .ant-card-head {
  border-bottom: none;
}

.customerDataTable.ant-card .ant-card-head-title {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.6px;
}

.customerDataTable.ant-card .ant-card-head {
  min-height: 48px;
}

.customerDataTable.ant-card .ant-card-body {
  /* padding: 24px 40px 15px; */
  padding: 0px;
  /* padding-bottom: 10px; */
}

.customerDetailDataTable.ant-card .ant-card-body {
  padding: 0px 0 16px 0px;
}

.customerDetailDataTable.ant-card .ant-card-head {
  border-bottom: none;
}

.transaction_table.customerDetailDataTable.ant-card .ant-card-body {
  padding: 10px 0 26px 0px;
}

.customer_search_input:hover {
  border-color: #d9d9d9 !important ;
}

.customer_search_input {
  margin-top: 5px;
  width: 300px;
}

.customer_search_input:focus-within {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}

.customer_search_input.ant-input-affix-wrapper {
  color: #a49f9f;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.56px;
  margin-left: 10px;
}

.customer_search_input.ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px;
}

.customer_search_input.ant-input-affix-wrapper > input.ant-input {
  font-weight: 600;
}

.table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #727d7a;
  font-size: 12px;
  line-height: 21px;
  width: 100%;
  padding: 12px 11px 12px 14px;
}

.table.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 16px;
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.table.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.table.ant-table-tbody.ant-table-tbody-virtual-scrollbar-horizontal {
  visibility: hidden;
}

.table.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #f5f5f5;
}

.table.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border: 1px solid #727d7a !important;
  box-shadow: none;
}

.table.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 5px 0;
}

.customer_detail_table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #727d7a;
  font-size: 12px;
  line-height: 21px;
  width: 100%;
  padding: 12px 11px 12px 10px;
}

.customer_detail_table.ant-table-wrapper .ant-table-cell {
  padding: 8px 12px;
}

.customer_detail_table.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 16px;
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.customer_detail_table.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.customer_detail_table.ant-table-tbody.ant-table-tbody-virtual-scrollbar-horizontal {
  visibility: hidden;
}

.customer_detail_table.ant-table-wrapper .ant-table {
  /* min-height: 250px; */
  min-height: 330.67px;
}

.customer_detail_table.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #f5f5f5;
}

.table.ant-table-wrapper .ant-table-cell {
  padding: 8px 16px;
}

/* CUSTOMER DETAILS */

.customer_portfolio_button.ant-btn-default {
  background: #00a971;
  /* padding: 10px 20px; */
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
}

.transaction_button.ant-btn-default {
  background: #00a971;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
}

.transaction_button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.customer_portfolio_button.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background: #00a971;
  border-color: #00a971;
  color: #fff;
}

.customer_portfolio_button.ant-btn-default {
  border-color: none;
}

/* .cards {
  margin-top: 32px;
} */

.customer-detail-card.ant-card .ant-card-body {
  padding: 24px 12px;
  border-radius: 20px;
  height: 350px;
  box-shadow: none;
  border: 1px solid #dddddd;
}

.pie-card.ant-card,
.customer-detail-card.ant-card {
  border-radius: 20px;
}

.pie-card.ant-card .ant-card-body {
  padding: 24px 12px 0 12px;
  height: 350px;
  box-shadow: none;
  border: 1px solid #dddddd;
  border-radius: 20px;

  /* padding-right: 0; */
}

.card-row {
  padding-top: 16px;
}

.customer-info {
  font-size: 14px;
  font-weight: 500;
}

.info {
  margin-top: 0;
  font-size: 10px;
  color: #808080;
  display: block;
  font-weight: 600;
}

.piechart {
  margin-top: 5px;
  padding-right: 19px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-card .ant-card-extra {
  display: flex;
  align-items: flex-end;
}

.editModelButton.ant-btn-default {
  border: 1px solid #00a971;
  color: #00a971;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.editModelButton.ant-btn-default:hover {
  border: 1px solid #00a971 !important;
  color: #00a971 !important;
}

.modelPortfolioCard.ant-card {
  border: 1px solid #dddddd;
  border-radius: 15px;
}

.modelPortfolioCard.ant-card .ant-card-head {
  padding: 0px 15px;
  border-bottom: none;
}

.modelPortfolioCard.ant-card .ant-card-body {
  padding: 0px 15px;
}

.stockTable {
  margin-top: 15px;
}

.stockTable.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 10px 16px;
  color: #727d7a;
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
}

.stockTable.ant-table-wrapper .ant-table {
  color: #2e2e2e;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.stockTable.ant-table-wrapper .ant-table-cell {
  padding: 8px 16px;
}

/* .ant-table-tbody-virtual-scrollbar-thumb {
  background: #b6b0b0 !important;
  width: 0.3em !important;
  /* height: 80px !important; */
/* }  */

/* TRANSACTION HISTORY */

.transaction_div {
  padding: 0 30px;
}

.transaction-heading {
  font-size: 20px;
  color: #1d1d1d;
  font-weight: 600;
  margin-bottom: 12px;
}

.transaction.ant-card {
  border-radius: 20px;
}

.status_toggle.ant-switch.ant-switch-checked {
  background: #0fa958;
}

.status_toggle.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0fa958;
}

.status_toggle.ant-switch .ant-switch-inner .ant-switch-inner-checked {
  font-size: 11px;
  line-height: 21px;
}

.status_toggle.ant-switch {
  background: #d9d9d9;
}

.status_toggle.ant-switch:hover:not(.ant-switch-disabled) {
  background: #d9d9d9;
}

.status_toggle.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  color: #717171;
  font-size: 11px;
  line-height: 21px;
}

.confirm_model.ant-modal .ant-modal-content {
  padding: 2rem;
}

.confirm_model.ant-modal .ant-modal-title {
  color: #1d1d1d;
  font-size: 18px;
  line-height: 21px;
}

.confirm_model.ant-modal .ant-modal-footer {
  text-align: center;
  margin-top: 3rem;
}

.confirm_model.ant-modal .ant-modal-footer .ant-btn {
  padding: 4px 4rem;
  height: 40px;
  border: 1px solid #00a971;
  color: #00a971;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.confirm_model.ant-modal .ant-modal-footer .ant-btn-primary {
  background: #00a971;
  color: #fff;
}
